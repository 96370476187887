import React, { FunctionComponent } from 'react';
import { Props } from './type';
import { Link } from 'gatsby';

import { HeaderTitle } from './style';

export const BlogPostPreview: FunctionComponent<Props> = ({
    path,
    title,
    description,
    date,
    excerpt,
}) => {
    return (
        <div key={path}>
            <HeaderTitle>
                <Link style={{ boxShadow: `none` }} to={`/blog/${path}`}>
                    {title}
                </Link>
            </HeaderTitle>
            <small>{date}</small>
            <p
                dangerouslySetInnerHTML={{
                    __html: description || excerpt,
                }}
            />
        </div>
    );
};
