import React, { FunctionComponent } from 'react';
import { graphql } from 'gatsby';

import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { BlogPostPreview } from '../components/BlogPostPreview';

interface Node {
    html: string;
    id?: string;
    frontmatter: {
        path?: string;
        title?: string;
        description?: string;
        body?: string;
        date?: Date;
        excerpt: string;
    };
}

interface Data {
    allMarkdownRemark: {
        nodes: Node[];
    };
}

interface Props {
    data: Data;
}

const BlogFeed: FunctionComponent<Props> = ({ data }) => {
    const { allMarkdownRemark: { nodes = [] } = {} } = data;
    return (
        <Layout>
            <SEO title="Blog" keywords={['']} />
            {nodes.map(node => {
                return (
                    <BlogPostPreview
                        key={node.id}
                        html={node.html}
                        {...node.frontmatter}
                    />
                );
            })}
        </Layout>
    );
};

export const query = graphql`
    query BlogFeedQuery {
        allMarkdownRemark(
            sort: { fields: frontmatter___sort, order: ASC }
            filter: { fileAbsolutePath: { regex: "/blog/" } }
        ) {
            nodes {
                id
                html
                frontmatter {
                    widget
                    title
                    date
                    description
                    slug
                    excerpt
                }
            }
        }
    }
`;

export default BlogFeed;
